import React from "react"
import { Helmet } from "react-helmet"

import LogoWhite from "../images/icons/logo_white.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Foot from "../components/foot"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import Button from "react-bootstrap/Button"
import { Card } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import Stack from "react-bootstrap/Stack"

const ContactUs = () => (
  <Layout>
    <SEO title="Contact Us" />
    <Helmet>
      <meta property="og:image" content={ LogoWhite } />
    </Helmet>
    <Container className="mt-4">
      <Row>
        <Col>
          <h3>Contact a Facility</h3>
        </Col>
        
      </Row>
      <Row className="mt-3"> 
        <Col xs={12} md className="mb-3">
          <Card>
            <Card.Header><h5>Grace Home Athena</h5></Card.Header>
            <Card.Body>
              <p style={{fontSize: "13pt"}}>
                <a style={{color: "#536eb1", textDecoration: "none"}} href="http://maps.apple.com/?address=35591,Athena+Court,Winchester,California">
                  35591 Athena Court <br /> 
                  Winchester, CA 92596 <br />
                </a>
                RCFE Facility No. 331880624 <br /><br />
                P: <a style={{color: "#536eb1", textDecoration: "none"}} href="tel:+19513257199">(951) 325-7199</a><br />
                F: <a style={{color: "#536eb1", textDecoration: "none"}} href="tel:+19516026077">(951) 602-6077</a>
              </p>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md>
          <Card>
            <Card.Header><h5>Grace Home Tavel</h5></Card.Header>
            <Card.Body>
              <p style={{fontSize: "13pt"}}>
                <a style={{color: "#536eb1", textDecoration: "none"}} href="http://maps.apple.com/?address=35224,Tavel+Street,Winchester,California">
                  35224 Tavel Street <br /> 
                  Winchester, CA 92596 <br />
                </a>
                RCFE Facility No. 331881283 <br /><br />
                P: <a style={{color: "#536eb1", textDecoration: "none"}} href="tel:+19519261101">(951) 926-1101</a><br />
                F: <a style={{color: "#536eb1", textDecoration: "none"}} href="tel:+19516026077">(951) 602-6077</a>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5 mb-5"> 
        <Col>
          <h3>Send Us a Message</h3>

          <Form className="pt-2" name="Contact Us" method="post" onSubmit="submit" action="/form-success" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="Contact Us" />
            <p hidden>
              <label>
                Don't fill this out: <input name="bot-field" />
              </label>
            </p>
            <Form.Group className="mb-4">
              <Form.Label className="mb-0" style={{fontSize: "13pt"}}>
                Name
              </Form.Label>
              <Form.Control name="name" type="text" style={{fontSize: "12pt"}} />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="mb-0" style={{fontSize: "13pt"}}>
                Email
              </Form.Label>
              <Form.Control name="email" type="email" style={{fontSize: "12pt"}} />
              <Form.Text className="text-muted" style={{fontSize: "10pt"}}>
                We'll never share your info with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="mb-0" style={{fontSize: "13pt"}}>
                Phone
              </Form.Label>
              <Form.Control name="phone" type="text" style={{fontSize: "12pt"}} />
            </Form.Group>

            <Form.Group>
              <Form.Label className="mb-0" style={{fontSize: "13pt"}}>
                Message
              </Form.Label>
              <Form.Control name="message" as="textarea" rows="4" placeholder="Message to the Facility Administrator" style={{fontSize: "12pt"}} />
            </Form.Group>

            <Stack className="mt-3 col-md-3 mx-auto">
              <Button style={{backgroundColor: "#536eb1", borderColor: "#536eb1", fontSize: "13pt"}} type="submit">
                Send Message
              </Button>
            </Stack>
          </Form>

        </Col>
      </Row>

      <div style={{marginTop: "125px"}}>
        <Foot />
      </div>
    </Container>
  </Layout>
)

export default ContactUs
